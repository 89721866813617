button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;

    &:hover {
        transform: translate(1px, 1px);
    }

    &:disabled {
        pointer-events: none;
        opacity: .5;
    }
}

.white {
    background: var(--white);
    border: 1px solid;
}

.red {
    background: var(--white);
    border: 1px solid var(--red50);
    color: var(--red50);
}

.redBg {
    background: var(--red50);
    border: 1px solid var(--red50);
    color: var(--white);
}
