.div {
    &>div {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .div {
        &>div {
            height: 400px !important;
        }
    }
}