.history {
    width: 80%;
    margin: 100px auto 240px;
}

.history div:nth-child(2) {
    border: none;
}

@media (max-width: 768px) {

    .history {
        width: 90%;
        margin: 50px auto 130px;
    }
   
    .history div:nth-child(1) {
        border: none;
    }

    .history> div:nth-child(2) {
        border-top: 1px solid #B4B4B4;
    }
}