.div {
    display: flex;
    align-items: center;
    height: 144px;
    border-top: 1px solid #B4B4B4;

    a {
        text-decoration: none;
    }
}

.img {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    height: 82.5px;
    min-height: 82.5px;
    max-height: 82.5px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    text-align: center;

    img {
        width: 52px;
        height: 80px;
    }
}

@media (max-width: 768px) {
    
    .div {
        height: 209px;
    }
}