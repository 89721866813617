.div {
    background-size: cover;
    height: 100%;
    min-height: 100vh;

    &>img {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 768px) {
    .div {
        &>img {
            position: absolute;
            z-index: -1;
            margin-top: -125px;
            width: 100%;
        }
    }
}