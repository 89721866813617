.div {
    margin-top: 100px;
}

.author {
    background-color: var(--grey80);
    
    &>div{
        width: 80%;
        margin: 0 auto;
        padding: 137px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1100px;
    }
}

.roadmap {
    background: #E8B221;
    padding-top: 124px;

    .container {
        width: 90%;
        max-width: 1050px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            width: 385px;
            height: 260px;
            margin-top: 135px;
        }
    }

    .header {
        display: flex;
        align-items: center;
        
        div {
            font-size: 20px;
            font-weight: bolder;
            font-family: Roboto;
            color: var(--white);
        }

        span {
            color: var(--white);
            font-size: 26px;
            font-family: Roboto;
            font-weight: 200;
        }
    }
}

.faq {
    margin-top: 113px;
    text-align: center;
    display: flex;
    justify-content: center;
}

@media (max-width: 1680px) {
    .div {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .author {
        &>div {
            flex-direction: column;
            padding: 96px 0 120px;
        }
    }

    .roadmap {
        padding-top: 88px;

        .container {
            flex-direction: column;
        }
    }

    .faq { 
        width: 90%;
        margin: 113px auto 0;
    }
}