.div {
    max-width: 620px;
    margin: 50px auto;
}

.row {
    padding: 12px 16px;
    background: white;
    border-radius: 8px;
    margin: 16px 0 0;

    &>div {
        margin-bottom: 8px;
    }

    input, select, textarea {
        outline: none;
        border: none;
        width: 100%;
        height: 30px;

        //styleName: Body 1;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }

    textarea {
        height: 100px;
    }
}

.row_group {
    display: flex;
    justify-content: space-between;

    .row {
        width: 48%;
    }
}

.btnFile {
    height: 32px;
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--red50);
    color: var(--red50);
    cursor: pointer;

    //styleName: SM/Button;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
}

@media (max-width: 768px) {
    
    .div {
        width: 90%;
    }

    .row_group {
        flex-direction: column;

        .row {
            width: 100%;
        }
    }
}